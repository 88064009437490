<script>
export default {
  name: "handles-tabs-mixin",
  methods: {
    createKeyFromTab(tab) {
      if (tab.to) {
        return this.$router.resolve(tab.to).href;
      }
      if (tab.href) {
        return tab.href;
      }
      return "undefined";
    }
  }
};
</script>
