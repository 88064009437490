<template>
  <v-fab-transition>
    <v-speed-dial
      v-if="actions.length > 0"
      v-show="!hidden"
      v-model="fab"
      :bottom="true"
      :right="true"
      fixed
      transition="slide-y-reverse-transition"
    >
      <v-btn slot="activator" v-model="fab" color="blue darken-2" dark fab>
        <v-icon>far fa-ellipsis-v</v-icon>
        <v-icon>far fa-times</v-icon>
      </v-btn>
      <v-tooltip
        v-for="(action, index) in actions"
        :key="index"
        :disabled="tooltipsDisabled"
        left
      >
        <v-btn
          slot="activator"
          fab
          dark
          small
          :color="action.color"
          :to="action.to"
        >
          <v-icon>{{ action.icon }}</v-icon>
        </v-btn>
        <span>{{ action.tag }}</span>
      </v-tooltip>
    </v-speed-dial>
  </v-fab-transition>
</template>

<script type="text/babel">
export default {
  name: "crud-header-actions",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => []
    },
    hidden: {
      type: Boolean,
      default: false
    },
    tooltipsDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fab: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  },
  watch: {
    hidden(newValue) {
      // When the actions get hidden, be sure to close the speed dial.
      if (newValue) {
        this.fab = false;
      }
    }
  }
};
</script>
