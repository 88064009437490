<template>
  <v-tabs v-model="activeTab" :color="color" slider-color="accent">
    <v-tab
      v-for="tab in tabs"
      :key="createKeyFromTab(tab)"
      :to="tab.to"
      :href="tab.href"
    >
      {{ tab.name }}
    </v-tab>
  </v-tabs>
</template>

<script type="text/babel">
import HandlesTabsMixin from "@/components/mixins/HandlesTabsMixin";

export default {
  name: "crud-header-tabs",
  mixins: [HandlesTabsMixin],
  props: {
    value: {
      type: String,
      default: null
    },
    tabs: {
      type: Array,
      default: () => []
    },
    color: {
      type: String,
      default: "primary"
    }
  },
  computed: {
    activeTab: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  }
};
</script>
