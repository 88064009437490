<template>
  <v-tabs-items v-model="activeTab">
    <v-tab-item
      v-for="tab in tabs"
      :key="createKeyFromTab(tab)"
      :value="createKeyFromTab(tab)"
    >
      <router-view v-if="activeTab === createKeyFromTab(tab)" />
    </v-tab-item>
  </v-tabs-items>
</template>

<script type="text/babel">
import HandlesTabsMixin from "@/components/mixins/HandlesTabsMixin";

export default {
  name: "crud-header-tab-items",
  mixins: [HandlesTabsMixin],
  props: {
    value: {
      type: String,
      default: null
    },
    tabs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    activeTab: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    },
    hasTabs() {
      return this.tabs.length > 0;
    }
  },
  created() {
    this.activeTab = this.$route.path;
  }
};
</script>
