<script type="text/babel">
export default {
  name: "crud-header-delete",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: undefined
    },
    softDelete: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    deleteHover: false
  }),
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on }">
      <v-btn
        @mouseover="deleteHover = true"
        @mouseleave="deleteHover = false"
        :color="deleteHover ? 'warning' : 'grey'"
        :flat="!deleteHover"
        v-on="on"
      >
        <v-icon small class="mr-2">far fa-fw fa-trash-alt</v-icon>
        DELETE
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline error text-uppercase white--text">
        CONFIRM {{ type }} DELETE
      </v-card-title>

      <v-card-text>
        <p>
          You are about to delete {{ type }}.
          {{ !softDelete ? "This action is non-reversible." : "" }}
        </p>
        <p><b>Are you sure you know what you're doing?</b></p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn flat @click="dialog = false">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          dark
          :loading="loading"
          @click="
            loading = true;
            $emit('remove');
          "
        >
          <v-icon small class="mr-2">far fa-fw fa-trash-alt</v-icon>
          DELETE
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
