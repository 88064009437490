<template>
  <div>
    <v-toolbar :color="color" dark :tabs="hasTabsSlot || hasTabs">
      <v-icon v-if="icon" @click="test">
        {{ icon }}
      </v-icon>
      <v-toolbar-title class="text-uppercase">
        <span v-if="hasType" :class="[`${color}--text`, 'text--lighten-2']">
          {{ type }}
        </span>
        {{ title }}
      </v-toolbar-title>
      <v-spacer />
      <template v-if="hasToolbarActionsSlot">
        <slot name="toolbarActions" />
        <v-divider vertical inset />
      </template>
      <template v-if="deletable">
        <crud-header-delete
          v-model="deleteDialog"
          :soft-delete="softDelete"
          :type="type"
          @remove="remove"
        />
        <v-divider vertical inset />
      </template>
      <v-btn
        v-if="revertable"
        icon
        :disabled="isLoading || !isChanged"
        @click="revert"
      >
        <v-icon>far fa-fw fa-undo</v-icon>
      </v-btn>
      <v-btn
        v-if="savable"
        icon
        :disabled="isLoading || !isChanged"
        :loading="isLoading"
        @click="validate(save)"
      >
        <v-icon>far fa-fw fa-save</v-icon>
      </v-btn>
      <slot v-if="hasTabsSlot" slot="extension" name="extension" />
      <crud-header-tabs
        v-else-if="hasTabs"
        slot="extension"
        :color="color"
        v-model="activeTab"
        :tabs="internalTabs"
      />
    </v-toolbar>
    <crud-header-tab-items
      v-if="hasTabsSlot || hasTabs"
      v-model="activeTab"
      :tabs="internalTabs"
    />
    <crud-header-actions v-model="fab" :actions="actions" :hidden="fabHidden" />
  </div>
</template>

<script type="text/babel">
import { isNil } from "lodash";
import CanValidate from "../../mixins/validation/CanValidate";
import { DELETE, SAVE } from "@/store/templates/crud/action-types";
import { REVERT, SET_IS_LOADING } from "@/store/templates/crud/mutation-types";
import { IS_CHANGED, IS_LOADING } from "@/store/templates/crud/getter-types";
import CrudHeaderTabItems from "@/components/generic/crud/CrudHeaderTabItems";
import CrudHeaderTabs from "@/components/generic/crud/CrudHeaderTabs";
import CrudHeaderActions from "@/components/generic/crud/CrudHeaderActions";
import CrudHeaderDelete from "@/components/generic/crud/CrudHeaderDelete";
import { createNamespacedHelpers } from "vuex";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");

export default {
  name: "crud-header",
  components: {
    CrudHeaderDelete,
    CrudHeaderActions,
    CrudHeaderTabs,
    CrudHeaderTabItems
  },
  inject: ["$validator"],
  mixins: [CanValidate],
  props: {
    store: {
      type: String,
      required: true
    },
    toolbarStyle: {
      type: String,
      required: false
    },
    title: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
    changed: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "primary"
    },
    tabs: {
      type: Array,
      default: () => []
    },
    actions: {
      type: Array,
      default: () => []
    },
    savable: {
      type: Boolean,
      default: true
    },
    revertable: {
      type: Boolean,
      default: true
    },
    deletable: {
      type: Boolean,
      default: false
    },
    softDelete: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    deleteDialog: false,
    activeTab: null,
    fab: false,
    fabHidden: false
  }),
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    isLoading: {
      get() {
        return this.$store.getters[`${this.store}/${IS_LOADING}`];
      },
      set(value) {
        return this.$store.commit(`${this.store}/${SET_IS_LOADING}`, value);
      }
    },
    hasTabs() {
      return this.tabs.length > 0;
    },
    hasToolbarActionsSlot() {
      return !isNil(this.$slots.toolbarActions);
    },
    hasTabsSlot() {
      return !isNil(this.$slots.extension);
    },
    isChanged() {
      return this.$store.getters[`${this.store}/${IS_CHANGED}`];
    },
    hasType() {
      return !isNil(this.type);
    },
    internalTabs() {
      return this.tabs.filter(tab =>
        this.hasRolesOrPermissions(tab.rolesOrPermissions)
      );
    }
  },
  watch: {
    isChanged(newVal) {
      this.$emit("is-changed", newVal);
    }
  },
  methods: {
    async save() {
      try {
        const id = await this.$store.dispatch(`${this.store}/${SAVE}`);
        this.$emit("save-success", { id });
      } catch (e) {
        this.$emit("save-failure", { exception: e });
        throw e;
      }
    },
    async remove() {
      const modelId = this.$store.getters[`${this.store}/id`];
      await this.$store.dispatch(`${this.store}/${DELETE}`, { id: modelId });
      this.deleteDialog = false;
      this.$emit("delete-success");
    },
    revert() {
      this.$store.commit(`${this.store}/${REVERT}`);
      this.$emit("revert");
    },
    test() {
      this.fabHidden = !this.fabHidden;
    }
  }
};
</script>
